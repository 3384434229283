<template>
  <b-form-group :questionId="questionId" :label="title" :label-for="questionId">
    <b-form-select
      :id="questionId"
      :options="options"
      required
      class="w-100 p-2 mb-4"
      @change="changeOption"
    >
      <template #first>
        <b-form-select-option :value="null" disabled
          >-- {{ $t('helpers.selectAnOption') }} --</b-form-select-option
        >
      </template>
    </b-form-select>
  </b-form-group>
</template>

<script>
export default {
  name: 'SecuritySelect',
  props: ['options', 'title', 'questionId'],
  data() {
    return {
      question: null,
      answer: null,
    };
  },
  methods: {
    changeOption(text) {
      this.question = text;
      this.$emit('setOption', { question: this.question, id: this.questionId });
    },
  },
};
</script>
