<template>
  <Layout>
    <PageHeader :title="title"></PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="text-center mb-3">
              {{ $t('security.securityQuestions') }}
            </h4>
            <p class="text-center">{{ $t('security.definition') }}</p>
            <div>
              <b-form @submit="handleSubmit">
                <security-select
                  :options="options"
                  :title="$t('security.firstQuestion')"
                  @setOption="getSelected"
                  :questionId="'firstQuestion'"
                ></security-select>
                <b-form-input
                  class="mb-4"
                  v-model="form.firstQuestion.answer"
                  type="text"
                  :placeholder="$t('helpers.answer')"
                  required
                ></b-form-input>
                <security-select
                  :options="options"
                  :title="$t('security.secondQuestion')"
                  @setOption="getSelected"
                  :questionId="'secondQuestion'"
                ></security-select>
                <b-form-input
                  class="mb-4"
                  v-model="form.secondQuestion.answer"
                  type="text"
                  :placeholder="$t('helpers.answer')"
                  required
                ></b-form-input>
                <b-button
                  v-if="!userHasSecurityQuestions"
                  type="submit"
                  variant="primary"
                  >{{
                    buttonText ? $t('helpers.update') : $t('helpers.add')
                  }}</b-button
                >
                <b-button
                  v-if="userHasSecurityQuestions"
                  type="submit"
                  variant="primary"
                  >{{ $t('helpers.update') }}</b-button
                >
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main.vue';
import PageHeader from '../../../components/page-header.vue';
// import { securityQuestions } from "../../../helpers/securityQuestions";
import SecuritySelect from '../../../components/securitySelect.vue';
import { getUserinfo } from '../../../helpers/userActions/userActions';
import Swal from 'sweetalert2';

export default {
  name: 'Settings',
  components: {
    Layout,
    PageHeader,
    SecuritySelect,
  },
  data() {
    return {
      title: this.$t('security.security'),
      form: {
        firstQuestion: {
          question: null,
          answer: null,
        },
        secondQuestion: {
          question: null,
          answer: null,
        },
      },
      options: null /* securityQuestions */,
      userHasSecurityQuestions: false,
      userSecurityQuestions: null,
      buttonText: false,
      sending: null,
    };
  },
  methods: {
    getSelected(e) {
      this.form[e.id].question = e.question;
    },

    handleSubmit(e) {
      e.preventDefault();
      const first = this.form.firstQuestion.question,
        second = this.form.secondQuestion.question;

      if (first === second) {
        return Swal.fire({
          title: 'Las preguntas no pueden ser las mismas',
          icon: 'info',
          timer: 1500,
        });
      }

      const { id } = getUserinfo();
      const { firstQuestion, secondQuestion } = this.form;
      const questions = [firstQuestion, secondQuestion];

      try {
        if (!this.buttonText) {
          const send = async () => {
            const newData = await this.settingQuestions(questions, id);
            if (newData[1].status === 200) {
              return Swal.fire({
                title: 'Guardado con éxito',
                icon: 'success',
                timer: 2000,
              }).then(() => {
                this.buttonText = true;
              });
            }

            Swal.fire({
              title: 'Error al crear las preguntas de seguridad',
              icon: 'info',
              timer: 2000,
            }).then(() => {
              this.buttonText = false;
            });
          };
          return send();
        }
        const send = async () => {
          const updateData = await this.updateQuestions(questions, id);

          if (updateData.status === 200) {
            return Swal.fire({
              title: 'Preguntas actualizadas con éxito',
              icon: 'success',
              timer: 2000,
            }).then(() => {
              this.buttonText = true;
            });
          }

          Swal.fire({
            title: 'Error al actualizar las preguntas',
            icon: 'info',
            timer: 2000,
          }).then(() => {
            this.buttonText = true;
          });
        };
        return send();
      } catch (error) {
        console.log(error);
      }
    },

    async settingQuestions(questions, id) {
      const questionsToSave = questions.map((question) => {
        const options = {
          userId: id,
          questionId: question.question,
          answer: question.answer,
        };
        return this.$http.post('questions/create', options);
      });

      return Promise.all(questionsToSave);
    },

    async updateQuestions(questions, id) {
      const questionsToSave = questions.map((question) => {
        const options = {
          questionId: question.question,
          answer: question.answer,
        };
        return options;
      });

      try {
        const response = await this.$http.put(
          `questions/${id}`,
          questionsToSave
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    },

    getSecurityQuestions() {
      //TODO: PETICION PARA OBTENER LAS PREGUNTAS ALMACENADAS EN LA DB

      try {
        const getQuestions = async () => {
          const { data } = await this.$http.get(`questions`);
          const { allQuestions } = data;
          //TODO: EL COMPONENTE DE SELECT REQUIERE DE LAS PROPIEDADES TEXT Y VALUE PARA MOSTRAR INFORMACION. PARA ENLAZAR CON EL BACK SE USARÁ EL VALUE
          const questionsFormated = allQuestions.map((question) => {
            return {
              text: question.question,
              value: question._id,
            };
          });

          return (this.options = questionsFormated);
        };
        getQuestions();
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    const { id } = getUserinfo();
    this.getSecurityQuestions();
    this.$http
      .get(`questions/${id}`)
      .then(({ data }) => {
        if (data.data.length) {
          this.buttonText = true;
        }
      })
      .catch((err) => console.log(err));
  },
};
</script>

<style></style>
